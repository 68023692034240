'use client'
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/components/provider.tsx
import { useAnalytics as useAnalytics2 } from "@everfund/event-detective";

// src/components/modal.tsx
import { FontAwesomeIcon as FontAwesomeIcon3 } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/sharp-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useAnalytics } from "@everfund/event-detective";
import { consentTypes } from "@everfund/event-gdpr-types";

// src/components/popup.tsx
import { useLayoutEffect } from "react";

// src/utils/cn.tsx
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// src/components/popup.tsx
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
function useLockBodyScroll() {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
}
var CookiePopup = ({
  buttons,
  bannerTitle = "Attention Needed",
  bannerDescription = "This site uses cookies to measure and improve your experience.",
  className,
  style
}) => {
  useLockBodyScroll();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      "div",
      __spreadProps(__spreadValues({}, { style, className }), {
        className: cn(
          "cb-fixed cb-inset-x-4 cb-bottom-4 cb-mx-auto cb-max-w-md cb-rounded-[--cc__radius--card] cb-bg-[--cc__bg] cb-p-4 cb-shadow md:cb-start-4 sm:cb-start-2 md:cb-end-auto md:cb-bottom-4 sm:cb-bottom-2 md:cb-w-auto sm:cb-w-full cb-fade-out-0 cb-fade-in-0 cb-z-[100] cb-duration-200 cb-animate-in",
          className
        ),
        children: [
          bannerTitle && /* @__PURE__ */ jsx("h2", { className: "cb-font-semibold cb-text-[--cc__fg]", children: bannerTitle }),
          bannerDescription && /* @__PURE__ */ jsx("p", { className: "cb-mt-2 cb-text-[--cc__secondary--fg] cb-text-sm", children: bannerDescription }),
          /* @__PURE__ */ jsxs("div", { className: "cb-mt-2 cb-flex cb-flex-col cb-justify-between cb-gap-4 sm:cb-flex-row", children: [
            /* @__PURE__ */ jsxs("div", { className: "cb-flex cb-flex-row cb-justify-between cb-gap-2", children: [
              buttons == null ? void 0 : buttons.optOut,
              buttons == null ? void 0 : buttons.customiseConsent
            ] }),
            buttons == null ? void 0 : buttons.optIn
          ] })
        ]
      })
    ),
    /* @__PURE__ */ jsx("div", { className: "cb-fixed cb-start-0 cb-end-0 cb-bottom-0 cb-z-[99] cb-h-full cb-from-[#f1f8f4] max-md:cb-bg-gradient-to-t md:cb-bg-gradient-to-tr cb-duration-200 cb-animate-in" })
  ] });
};
var popup_default = CookiePopup;

// src/components/shad-ui/accordion.tsx
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/sharp-solid-svg-icons";
import { Content, Header, Item, Root, Trigger } from "@radix-ui/react-accordion";
import { forwardRef } from "react";
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var Accordion = Root;
var AccordionItem = forwardRef(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    return /* @__PURE__ */ jsx2(Item, __spreadValues({ ref, className: cn(className) }, props));
  }
);
AccordionItem.displayName = "AccordionItem";
var AccordionTrigger = forwardRef(
  (_a, ref) => {
    var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
    return /* @__PURE__ */ jsx2(Header, { className: "flex", children: /* @__PURE__ */ jsxs2(
      Trigger,
      __spreadProps(__spreadValues({
        ref,
        className: cn(
          "cb-flex cb-flex-1 cb-items-center cb-justify-between cb-py-4 cb-font-medium cb-transition-all [&[data-state=open]>svg]:cb-rotate-180 hover:cb-underline",
          className
        )
      }, props), {
        children: [
          children,
          /* @__PURE__ */ jsx2(
            FontAwesomeIcon,
            {
              icon: faChevronDown,
              className: "cb-h-4 cb-w-4 cb-shrink-0 cb-transition-transform cb-duration-200"
            }
          )
        ]
      })
    ) });
  }
);
AccordionTrigger.displayName = Trigger.displayName;
var AccordionContent = forwardRef(
  (_a, ref) => {
    var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
    return /* @__PURE__ */ jsx2(
      Content,
      __spreadProps(__spreadValues({
        ref,
        className: "cb-overflow-hidden cb-text-sm cb-transition-all data-[state=closed]:cb-animate-accordion-up data-[state=open]:cb-animate-accordion-down"
      }, props), {
        children: /* @__PURE__ */ jsx2("div", { className: cn(className), children })
      })
    );
  }
);
AccordionContent.displayName = Content.displayName;

// src/components/shad-ui/button.tsx
import { Slot } from "@radix-ui/react-slot";
import { forwardRef as forwardRef2 } from "react";
import { tv } from "tailwind-variants";
import { jsx as jsx3 } from "react/jsx-runtime";
var cookieButtonVariants = tv({
  base: "cb-inline-flex cb-items-center cb-justify-center cb-whitespace-nowrap cb-rounded-[--cc__radius--button] cb-text-sm cb-font-medium cb-ring-offset-[--cc__bg] cb-transition-colors cb-focus-visible:outline-none focus-visible:cb-ring-2 focus-visible:cb-ring-[--cc__ring] focus-visible:cb-ring-offset-2 disabled:cb-pointer-events-none disabled:cb-opacity-50",
  variants: {
    variant: {
      default: "cb-bg-[--cc__accent] cb-text-[--cc__accent--fg] hover:cb-bg-[--cc__accent]/90",
      outline: "cb-border cb-text-[--cc__fg] cb-border-[--cc__input] cb-bg-[--cc__bg] hover:cb-bg-[--cc__accent] hover:cb-text-[--cc__accent--fg]"
    },
    size: {
      default: "cb-h-10 cb-px-4 cb-py-2",
      wide: "cb-h-10 cb-px-4 cb-py-2 cb-px-6"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});
var CookieButton = forwardRef2(
  (_a, ref) => {
    var _b = _a, { className, variant, asChild = false, type } = _b, props = __objRest(_b, ["className", "variant", "asChild", "type"]);
    const Comp = asChild ? Slot : "button";
    return /* @__PURE__ */ jsx3(Comp, __spreadProps(__spreadValues({ className: cn(cookieButtonVariants({ variant, className })), ref }, props), { type }));
  }
);
CookieButton.displayName = "Button";

// src/components/shad-ui/dialog.tsx
import { FontAwesomeIcon as FontAwesomeIcon2 } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { Close, Content as Content2, Description, Overlay, Portal, Root as Root2, Title, Trigger as Trigger2 } from "@radix-ui/react-dialog";
import { forwardRef as forwardRef3 } from "react";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var Dialog = Root2;
var DialogTrigger = Trigger2;
var DialogPortal = Portal;
var DialogClose = Close;
var DialogOverlay = forwardRef3(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    return /* @__PURE__ */ jsx4(
      Overlay,
      __spreadValues({
        ref,
        className: cn(
          "data-[state=closed]:cb-fade-out-0 data-[state=open]:cb-fade-in-0 cb-fixed cb-inset-0 cb-z-[200] cb-bg-black/80 data-[state=closed]:cb-animate-out data-[state=open]:cb-animate-in",
          className
        )
      }, props)
    );
  }
);
DialogOverlay.displayName = Overlay.displayName;
var DialogContent = forwardRef3(
  (_a, ref) => {
    var _b = _a, { className, children } = _b, props = __objRest(_b, ["className", "children"]);
    return /* @__PURE__ */ jsxs3(DialogPortal, { children: [
      /* @__PURE__ */ jsx4(DialogOverlay, {}),
      /* @__PURE__ */ jsxs3(
        Content2,
        __spreadProps(__spreadValues({
          ref,
          className: cn(
            "data-[state=closed]:cb-fade-out-0 data-[state=open]:cb-fade-in-0 data-[state=closed]cb-:zoom-out-95 data-[state=open]:cb-zoom-in-95 data-[state=closed]:cb-slide-out-to-left-1/2 data-[state=closed]:cb-slide-out-to-top-[48%] data-[state=open]:cb-slide-in-from-left-1/2 data-[state=open]:cb-slide-in-from-top-[48%] cb-fixed cb-top-[50%] cb-left-[50%] cb-z-[2001] cb-grid cb-w-full cb-max-w-lg cb-translate-x-[-50%] cb-translate-y-[-50%] cb-gap-4 cb-border cb-bg-background cb-p-6 cb-shadow-lg cb-duration-200 data-[state=closed]:cb-animate-out data-[state=open]:cb-animate-in sm:cb-rounded-[--cc__radius--card]",
            className
          )
        }, props), {
          children: [
            children,
            /* @__PURE__ */ jsxs3(Close, { children: [
              /* @__PURE__ */ jsx4(FontAwesomeIcon2, { icon: faXmark, className: "cb-size-4" }),
              /* @__PURE__ */ jsx4("span", { className: "sr-only", children: "Close" })
            ] })
          ]
        })
      )
    ] });
  }
);
DialogContent.displayName = Content2.displayName;
var DialogHeader = (_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx4("div", __spreadValues({ className: cn("cb-flex cb-flex-col cb-space-y-1.5 cb-text-center sm:cb-text-start", className) }, props));
};
DialogHeader.displayName = "DialogHeader";
var DialogFooter = (_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx4(
    "div",
    __spreadValues({
      className: cn("cb-flex cb-flex-col-reverse sm:cb-flex-row sm:cb-justify-end sm:cb-space-x-2", className)
    }, props)
  );
};
DialogFooter.displayName = "DialogFooter";
var DialogTitle = forwardRef3(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    return /* @__PURE__ */ jsx4(
      Title,
      __spreadValues({
        ref,
        className: cn("cb-font-semibold cb-text-lg cb-leading-none cb-tracking-tight", className)
      }, props)
    );
  }
);
DialogTitle.displayName = Title.displayName;
var DialogDescription = forwardRef3(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    return /* @__PURE__ */ jsx4(Description, __spreadValues({ ref, className: cn("cb-text-muted-fg cb-text-sm", className) }, props));
  }
);
DialogDescription.displayName = Description.displayName;

// src/components/shad-ui/form.tsx
import { Slot as Slot2 } from "@radix-ui/react-slot";
import {
  createContext,
  forwardRef as forwardRef5,
  useContext,
  useId
} from "react";
import {
  Controller,
  FormProvider,
  useFormContext
} from "react-hook-form";

// src/components/shad-ui/label.tsx
import { Root as Root3 } from "@radix-ui/react-label";
import { forwardRef as forwardRef4 } from "react";
import { tv as tv2 } from "tailwind-variants";
import { jsx as jsx5 } from "react/jsx-runtime";
var labelVariants = tv2({
  base: "cb-font-medium cb-text-sm cb-leading-none cb-peer-disabled:cursor-not-allowed cb-peer-disabled:opacity-70"
});
var Label = forwardRef4((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx5(Root3, __spreadValues({ ref, className: cn(labelVariants(), className) }, props));
});
Label.displayName = Root3.displayName;

// src/components/shad-ui/form.tsx
import { jsx as jsx6 } from "react/jsx-runtime";
var Form = FormProvider;
var FormFieldContext = createContext({});
var FormField = (_a) => {
  var props = __objRest(_a, []);
  return /* @__PURE__ */ jsx6(FormFieldContext.Provider, { value: { name: props.name }, children: /* @__PURE__ */ jsx6(Controller, __spreadValues({}, props)) });
};
var useFormField = () => {
  const fieldContext = useContext(FormFieldContext);
  const itemContext = useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();
  const fieldState = getFieldState(fieldContext.name, formState);
  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }
  const { id } = itemContext;
  return __spreadValues({
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`
  }, fieldState);
};
var FormItemContext = createContext({});
var FormItem = forwardRef5((_a, ref) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  const id = useId();
  return /* @__PURE__ */ jsx6(FormItemContext.Provider, { value: { id }, children: /* @__PURE__ */ jsx6("div", __spreadValues({ ref, className: cn("cb-space-y-2", className) }, props)) });
});
FormItem.displayName = "FormItem";
var FormLabel = forwardRef5(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    const { error, formItemId } = useFormField();
    return /* @__PURE__ */ jsx6(Label, __spreadValues({ ref, className: cn(error && "cb-text-destructive", className), htmlFor: formItemId }, props));
  }
);
FormLabel.displayName = "FormLabel";
var FormControl = forwardRef5((_a, ref) => {
  var props = __objRest(_a, []);
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();
  return /* @__PURE__ */ jsx6(
    Slot2,
    __spreadValues({
      ref,
      id: formItemId,
      "aria-describedby": !error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`,
      "aria-invalid": !!error
    }, props)
  );
});
FormControl.displayName = "FormControl";
var FormDescription = forwardRef5(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    const { formDescriptionId } = useFormField();
    return /* @__PURE__ */ jsx6("p", __spreadValues({ ref, id: formDescriptionId, className: cn("cb-text-muted-fg cb-text-sm", className) }, props));
  }
);
FormDescription.displayName = "FormDescription";

// src/components/shad-ui/switch.tsx
import { Root as Root4, Thumb } from "@radix-ui/react-switch";
import { forwardRef as forwardRef6 } from "react";
import { jsx as jsx7 } from "react/jsx-runtime";
var Switch = forwardRef6(
  (_a, ref) => {
    var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
    return /* @__PURE__ */ jsx7(
      Root4,
      __spreadProps(__spreadValues({
        className: cn(
          "cb-peer cb-inline-flex cb-h-6 cb-w-11 cb-shrink-0 cb-cursor-pointer cb-items-center cb-rounded-full cb-border-2 cb-border-transparent cb-transition-colors focus-visible:cb-outline-none focus-visible:cb-ring-2 focus-visible:cb-ring-ring focus-visible:cb-ring-offset-2 focus-visible:cb-ring-offset-background disabled:cb-cursor-not-allowed disabled:cb-opacity-50 data-[state=checked]:cb-bg-primary data-[state=unchecked]:cb-bg-input",
          className
        )
      }, props), {
        ref,
        children: /* @__PURE__ */ jsx7(
          Thumb,
          {
            className: cn(
              "cb-pointer-events-none cb-block cb-h-5 cb-w-5 cb-rounded-full cb-bg-background cb-shadow-lg cb-ring-0 cb-transition-transform data-[state=checked]:cb-translate-x-5 data-[state=unchecked]:cb-translate-x-0"
            )
          }
        )
      })
    );
  }
);
Switch.displayName = Root4.displayName;

// src/components/modal.tsx
import { jsx as jsx8, jsxs as jsxs4 } from "react/jsx-runtime";
var CookieModal = ({ requiredGdprPurposes, style, className }) => {
  const { consent } = useAnalytics();
  const mergedList = consentTypes.map((cookie) => {
    if (requiredGdprPurposes.includes(cookie.name) || cookie.display === true) {
      return __spreadProps(__spreadValues({}, cookie), {
        display: true
      });
    }
    return cookie;
  }).filter(({ display }) => display === true);
  const defaultValues = mergedList.reduce(
    (acc, cookie) => {
      acc[cookie.name] = !!cookie.defaultValue;
      return acc;
    },
    {}
  );
  const form = useForm({
    defaultValues
  });
  const onSubmit = form.handleSubmit((data) => {
    const gdprPurposes = defaultValues;
    Object.entries(data).forEach(([key, value]) => {
      if (!value) {
        return;
      }
      gdprPurposes[key] = value;
      return { key, value };
    });
    consent({ type: "custom", gdprPurposes });
  });
  return /* @__PURE__ */ jsxs4(Dialog, { children: [
    /* @__PURE__ */ jsx8(
      popup_default,
      __spreadProps(__spreadValues({}, { style, className }), {
        buttons: {
          optIn: /* @__PURE__ */ jsx8(
            CookieButton,
            {
              className: "cb-px-8",
              size: "wide",
              onClick: () => {
                consent({ type: "all" });
              },
              children: "Accept"
            }
          ),
          customiseConsent: /* @__PURE__ */ jsx8(DialogTrigger, { asChild: true, children: /* @__PURE__ */ jsx8(CookieButton, { variant: "outline", className: "cb-w-full sm:cb-w-auto", children: "Details" }) }),
          optOut: /* @__PURE__ */ jsx8(
            CookieButton,
            {
              variant: "outline",
              className: "cb-w-full sm:cb-w-auto",
              onClick: () => {
                consent({ type: "minimum" });
              },
              children: "Opt-out"
            }
          )
        }
      })
    ),
    /* @__PURE__ */ jsx8(DialogContent, __spreadProps(__spreadValues({ className: "cb-border-0 cb-p-0 sm:cb-max-w-lg" }, { style }), { children: /* @__PURE__ */ jsx8(Form, __spreadProps(__spreadValues({}, form), { children: /* @__PURE__ */ jsxs4(
      "form",
      {
        onSubmit,
        className: "cb-mx-2 cb-space-y-6 cb-overflow-hidden cb-rounded-[--cc__radius--button] cb-border-[--cc__border] cb-bg-[--cc__bg] cb-p-6 sm:cb-mx-0 sm:cb-rounded-[--cc__radius--card]",
        children: [
          /* @__PURE__ */ jsxs4(DialogHeader, { children: [
            /* @__PURE__ */ jsx8(DialogTitle, { className: "cb-text-[--cc__fg]", children: "Cookie Preferences" }),
            /* @__PURE__ */ jsx8(DialogDescription, { className: "cb-text-[--cc__secondary--fg]", children: 'We use cookies to improve your site experience. The "strictly necessary" cookies are required for Everfund to function.' })
          ] }),
          /* @__PURE__ */ jsx8(
            Accordion,
            {
              type: "single",
              defaultValue: "necessary",
              collapsible: true,
              className: "cb-w-full cb-overflow-hidden cb-rounded-lg cb-border cb-border-[--cc__border]",
              children: /* @__PURE__ */ jsx8("div", { className: "divide-y divide-[--cc__border]", children: mergedList.map((cookie) => {
                const isSelected = cookie.disabled ? cookie.defaultValue : form.watch(cookie.name);
                return /* @__PURE__ */ jsxs4(AccordionItem, { value: cookie.name, className: "", children: [
                  /* @__PURE__ */ jsx8(AccordionTrigger, { className: "cb-px-4 [&>[can-rotate=true]]:cb-fill-[--cc__secondary--fg]", children: /* @__PURE__ */ jsxs4("div", { className: "cb-flex cb-flex-row cb-items-center cb-space-x-2 cb-text-[--cc__fg] cb-capitalize", children: [
                    isSelected ? /* @__PURE__ */ jsx8(
                      FontAwesomeIcon3,
                      {
                        icon: faCircleCheck,
                        className: "!cb-rotate-0 cb-h-4 cb-w-4 cb-text-[--cc__icon--allowed]"
                      }
                    ) : /* @__PURE__ */ jsx8(
                      FontAwesomeIcon3,
                      {
                        icon: faCircleXmark,
                        className: "!cb-rotate-0 cb-h-4 cb-w-4 cb-text-[--cc__icon--restricted]"
                      }
                    ),
                    /* @__PURE__ */ jsx8("span", { children: cookie.name })
                  ] }) }),
                  /* @__PURE__ */ jsx8(AccordionContent, { className: "cb-overflow-hidden", children: /* @__PURE__ */ jsx8(
                    FormField,
                    {
                      control: form.control,
                      name: cookie.name,
                      disabled: cookie.disabled,
                      render: ({ field }) => /* @__PURE__ */ jsxs4(FormItem, { className: "cb-flex cb-flex-row cb-items-center cb-justify-between cb-space-x-4 cb-overflow-hidden cb-border-[--cc__border] cb-border-t cb-bg-[--cc__muted] cb-p-4", children: [
                        /* @__PURE__ */ jsx8(FormControl, { children: /* @__PURE__ */ jsx8(
                          Switch,
                          {
                            checked: field.value,
                            onCheckedChange: field.onChange,
                            disabled: cookie.disabled,
                            "aria-readonly": cookie.disabled,
                            className: "cb-ring-[--cc__ring] [&>span]:cb-bg-[--cc__switch--fg] data-[state=checked]:cb-bg-[--cc__switch--bg] data-[state=unchecked]:cb-bg-[--cc__input] focus-visible:cb-ring-offset-[--cc__bg]"
                          }
                        ) }),
                        /* @__PURE__ */ jsxs4("div", { className: "cb-space-y-0.5", children: [
                          /* @__PURE__ */ jsx8(FormLabel, { className: "cb-sr-only", children: cookie.name }),
                          /* @__PURE__ */ jsx8(FormDescription, { className: "cb-text-[--cc__fg]", children: cookie.description })
                        ] })
                      ] })
                    }
                  ) })
                ] }, cookie.name);
              }) })
            }
          ),
          /* @__PURE__ */ jsx8(DialogFooter, { className: "justify-end", children: /* @__PURE__ */ jsx8(DialogClose, { asChild: true, children: /* @__PURE__ */ jsx8(CookieButton, { variant: "default", type: "submit", children: "Save Current Settings" }) }) })
        ]
      }
    ) })) }))
  ] });
};

// src/components/provider.tsx
import { Fragment as Fragment2, jsx as jsx9, jsxs as jsxs5 } from "react/jsx-runtime";
var CookieBannerProvider = (props) => {
  var _a;
  const { isLoaded, state } = useAnalytics2();
  return /* @__PURE__ */ jsxs5(Fragment2, { children: [
    isLoaded && !((_a = state == null ? void 0 : state.consent) == null ? void 0 : _a.dateConsented) && /* @__PURE__ */ jsx9(CookieModal, { requiredGdprPurposes: props.requiredGdprPurposes }),
    props.children
  ] });
};
export {
  CookieBannerProvider
};
