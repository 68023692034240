'use client';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const getLocalStorage = () => {
  if (typeof window !== 'undefined') {
    return localStorage;
  }
};

const localStoragePersister = createSyncStoragePersister({
  storage: getLocalStorage(),
});

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});
